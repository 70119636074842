button{
    width: 10px;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    position:relative;
    width: auto;
    color:#ecf0f1;
    text-decoration:none;
    border:solid 1px rgb(135, 75, 143);
    background:rgb(135, 75, 143);
    text-align:center;
    padding:16px 18px 14px;
    font-family: "Press Start 2P", "VT323";
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
  
    -webkit-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.479);
    -moz-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.712)0;
    box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.753);
  }
  
button:active{
    -webkit-box-shadow: 0px 2px 0px rgba(6, 2, 7, 0.753);
    -moz-box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
    box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
    position:relative;
    top:4px;
  }

  .mint-button{
    margin-top: 30px;
  }

  .mint-num{
    text-align: center;
    font-family: "Press Start 2P", "VT323";
    height: 1.5rem;
    background-color: white;
  }

  .supply{
    margin-top: 30px;
  }