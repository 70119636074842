.nav-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.nav-right{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-right: 20px;
}

p{
  margin-right: 20px;
}

.logo{
    width: 5%;
    height: 5%;
}

.twitter{
    margin-right: 20px;
    height: 50px;
    width: 50px;
    color: rgb(0, 195, 255);
}

.connect {
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  position:relative;
  width: auto;
  color:#ecf0f1;
  text-decoration:none;
  border:solid 1px rgb(135, 75, 143);
  background:rgb(135, 75, 143);
  text-align:center;
  padding:16px 18px 14px;

  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;

  -webkit-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.479);
  -moz-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.712)0;
  box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.753);
}

.connect:active{
  -webkit-box-shadow: 0px 2px 0px rgba(6, 2, 7, 0.753);
  -moz-box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
  box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
  position:relative;
  top:4px;
}