@import url("https:/fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https:/fonts.googleapis.com/css?family=VT323");

.app {
  text-align: center;
  height: 100vh;
  font-family: "Press Start 2P", "VT323";
  color: rgb(255, 255, 255);
}


body {
  background: radial-gradient(circle at bottom, navy 0, black 100%);
  height: 100vh;
  overflow: hidden;
}

.counter{
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
}

.number{
  width: 100px;
  height: 30px;
  border: 2px solid rgb(130, 95, 134);
  background-color: rgb(203, 168, 207);
  font-weight: bold;
  font-size: 1rem;
}

.num-button{
  width: 10px;
  height: 50px;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 4px;
  position:relative;
  width: auto;
  color:#ecf0f1;
  text-decoration:none;
  border:solid 1px rgb(135, 75, 143);
  background:rgb(135, 75, 143);
  text-align:center;
  padding:16px 18px 14px;

  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;

  -webkit-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.479);
  -moz-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.712)0;
  box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.753);
}

.num-button:active{
  -webkit-box-shadow: 0px 2px 0px rgba(6, 2, 7, 0.753);
  -moz-box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
  box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
  position:relative;
  top:4px;
}

.mint{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mint-button{
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    position:relative;
    width: auto;
    color:#ecf0f1;
    text-decoration:none;
    border:solid 1px rgb(135, 75, 143);
    background:rgb(135, 75, 143);
    text-align:center;
    padding:16px 18px 14px;
  
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
  
    -webkit-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.479);
    -moz-box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.712)0;
    box-shadow: 0px 6px 0px rgba(135, 75, 143, 0.753);
  }
  
  .mint-button:active{
    -webkit-box-shadow: 0px 2px 0px rgba(6, 2, 7, 0.753);
    -moz-box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
    box-shadow: 0px 2px 0px rgba(135, 75, 143, 0.753);
    position:relative;
    top:4px;
  }

.main-container{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50vh;
}

.space {
  background: rgba(128, 0, 128, 0.1) center / 200px 200px round;
  border: 1px dashed purple;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.stars1 {
  animation: space 180s ease-in-out infinite;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}
.stars2 {
  animation: space 240s ease-in-out infinite;
  background-image:
    radial-gradient(
      1px 1px at 75px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 100px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 199px 100px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 20px 50px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 100px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 5px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    );
  
}
.stars3 {
  animation: space 300s ease-in-out infinite;
  background-image:
    radial-gradient(
      1px 1px at 10px 10px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 150px 150px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 60px 170px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 175px 180px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 195px 95px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 95px 145px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}

@keyframes space {
  40% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.25;
  }
  60% {
    opacity: 0.75;
  }
  100% {
    transform: rotate(360deg);
  }
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: white
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(135, 75, 143);
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}